<template>
  <CRow>
    <CCol md="12">
      <ProductPP v-model="pp" :ppls="ppl_stats" />
    </CCol>
    <CCol md="12">
      <ProductPPLS v-model="ppls_config" :pp="pp" @UpdateLots="UpdateLots" />
    </CCol>
  </CRow>
</template>

<script>
import { GetDataManager, ApiCall } from "../../../ds/index";
import { Query, Predicate } from "@syncfusion/ej2-data";

import ProductPP from "./ProductPP";
import ProductPPLS from "./ProductPPLS";

export default {
  data() {
    return {
      pp: null,
      ppls_config: {
        ppls: null,
        search: "",
        active: null,
      },
      awaitingSearch: false,
    };
  },
  watch: {
    "ppls_config.active": function () {
      this.UpdateLots();
    },
    "ppls_config.search": function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.UpdateLots();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    UpdateLots() {
      let self = this;
      self.ppls_config.ppls = null;

      let predicate = new Predicate(
        "productproducer",
        "equal",
        this.$route.params.pp_id
      );

      if (this.ppls_config.active == true) {
        predicate = predicate.and("is_active", "equal", true);
      } else if (this.ppls_config.active == false) {
        predicate = predicate.and("is_active", "equal", false);
      }

      let query = new Query().where(predicate);
      if (!this.ppls_config.search == "") {
        query = query.search(this.ppls_config.search, [
          "translations." + this.$i18n.locale + ".name",
          "translations." + this.$i18n.locale + ".lot_description",
        ]);
      }
      // Update lots info
      ApiCall(
        GetDataManager("role_producer_products_lots", [
          this.$store.state.role.id,
        ]),
        query,
        function (e) {
          self.ppls_config.ppls = e.result;
        }
      );
    },
  },
  created() {
    let self = this;
    // Update propduct Info
    ApiCall(
      GetDataManager(
        "role_producer_products",
        [this.$store.state.role.id],
        this.$route.params.pp_id
      ),
      new Query(),
      function (e) {
        self.pp = e.result;
        self.$route.meta.label =
          "PP" +
          self.pp.id +
          " " +
          self.pp.translations[self.$i18n.locale].name;
        // Add a temporary query parameter.
        self.$router.replace({ query: { temp: Date.now() } });
        // Remove the temporary query parameter.
        self.$router.replace({ query: { temp: undefined } });
      }
    );
    this.UpdateLots();
  },
  computed: {
    ppl_stats() {
      let res = {
        tot: 0,
        active: 0,
        inactive: 0,
        max_price: null,
        min_price: null,
      };

      if (this.ppls_config.ppls) {
        res = this.ppls_config.ppls.reduce(function (map, obj) {
          //   map.push({ value: obj.code, label: obj.name });
          if (obj.is_active) {
            map.active += 1;
            if (
              map.max_price == null ||
              parseFloat(obj.price) > parseFloat(map.max_price)
            ) {
              map.max_price = obj.price;
            }
            if (
              map.min_price == null ||
              parseFloat(obj.price) < parseFloat(map.min_price)
            ) {
              map.min_price = obj.price;
            }
          } else {
            map.inactive += 1;
          }

          map.tot += 1;
          return map;
        }, res);
      }
      return res;
    },
  },
  components: {
    ProductPP,
    ProductPPLS,
  },
};
</script>
